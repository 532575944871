import { useEffect } from "react"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import resourcesToBackend from "i18next-resources-to-backend"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import ISO6391 from "iso-639-1"

import { useReduxState } from "@/hooks/use_redux_state"

import heLanguage from "../locales/he.json"
import enLanguage from "../locales/en.json"
import arLanguage from "../locales/ar.json"
import jaLanguage from "../locales/ja.json"

const bundledResources = {
  en: enLanguage,
  he: heLanguage,
  ar: arLanguage,
  ja: jaLanguage,
}

const localBackend = resourcesToBackend((lng, ns) => bundledResources[lng])

i18n
  .use(initReactI18next)
  .use(localBackend)
  .init({
    react: {
      useSuspense: false,
    },
  })

const I18Provider = ({ children }) => {
  const [currentLanguageCode, setCurrentLanguageCode] = useReduxState({ key: "currentLanguageCode" })
  const configuration = useSelector((state) => state.configuration)

  useEffect(() => {
    if (!currentLanguageCode && configuration) {
      setCurrentLanguageCode(ISO6391.getCode(configuration.defaultLanguage))
    }
    if (currentLanguageCode && currentLanguageCode !== i18n.language) {
      i18n.changeLanguage(currentLanguageCode)
    }
  }, [currentLanguageCode, configuration, setCurrentLanguageCode])

  return children
}

I18Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default I18Provider

import { AwsRum } from "aws-rum-web"

let awsRum = null

function setupConsoleOutput(recordConsoleEvent, eventType) {
  const originalConsoleFunction = console[eventType]
  console[eventType] = (...args) => {
    originalConsoleFunction(...args)

    recordConsoleEvent(`console_${eventType}`, args)
  }
}

function setupConsoleEvents(awsRum) {
  function setupConsoleEvent(eventType, args) {
    awsRum.recordEvent(eventType, {
      ...args,
    })
  }

  const events = ["log", "info", "warn", "error"]

  events.forEach((eventType) => {
    setupConsoleOutput(setupConsoleEvent, eventType)
  })
}

function initClient({ application_id, identity_pool_id, region }) {
  try {
    const config = {
      sessionSampleRate: 1,
      identityPoolId: identity_pool_id,
      endpoint: `https://dataplane.rum.${region}.amazonaws.com`,
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false,
    }

    const APPLICATION_VERSION = process.env.NEXT_PUBLIC_PORTAL_VERSION || "unknown"
    awsRum = new AwsRum(application_id, APPLICATION_VERSION, region, config)
    setupConsoleEvents(awsRum)
  } catch (error) {
    console.error("Failed to initialize AWS RUM", error)
  }
}

export const initAwsRum = () => {
  if (!awsRum) {
    const domain = window.location.hostname

    if (domain === "localhost") {
      console.warn("AWS RUM is disabled on localhost")
    } else if (domain === process.env.NEXT_PUBLIC_PORTAL_FRONTEND_INTERNAL_DOMAIN_NAME) {
      initClient({
        application_id: process.env.NEXT_PUBLIC_INTERNAL_DOMAIN_RUM_APPLICATION_ID,
        identity_pool_id: process.env.NEXT_PUBLIC_INTERNAL_DOMAIN_RUM_IDENTITY_POOL_ID,
        region: process.env.NEXT_PUBLIC_AWS_DEFAULT_REGION,
      })
    } else if (domain === process.env.NEXT_PUBLIC_PORTAL_FRONTEND_EXTERNAL_DOMAIN_NAME) {
      initClient({
        application_id: process.env.NEXT_PUBLIC_EXTERNAL_DOMAIN_RUM_APPLICATION_ID,
        identity_pool_id: process.env.NEXT_PUBLIC_EXTERNAL_DOMAIN_RUM_IDENTITY_POOL_ID,
        region: process.env.NEXT_PUBLIC_AWS_DEFAULT_REGION,
      })
    } else {
      console.warn("AWS RUM is disabled on unknown domain")
    }
  }
}

export const getAwsRum = () => {
  return awsRum
}

export const SetupRumTestError = () => {
  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search)
    const testError = params.get("test_rum_client_error")
    if (testError) {
      console.error(testError)
      throw new Error(testError)
    }
  }
}

"use client"

import { SnackbarProvider } from "notistack"
import NiceModal from "@ebay/nice-modal-react"

import I18Provider from "@/molecules/i18_provider"
import AppHeader from "@/organism/app_header"
import { init as initAxios } from "@/molecules/axios"
import FavIcon from "@/molecules/fav_icon"
import useInacvtivityCheck from "@/hooks/use_inactivity_check"
import DataLoader from "@/components/data_loader"
import Theme from "@/molecules/theme"
import ErrorBoundary from "@/utils/error_boundary"
import useLatestBuild from "@/hooks/use_latest_build"
import useConfiguration from "@/hooks/use_configuration"
import { initAwsRum, SetupRumTestError } from "@/utils/aws_rum"

const App = ({ children }) => {
  useInacvtivityCheck()
  useLatestBuild("App")
  initAxios()
  initAwsRum()
  useConfiguration()

  return (
    <I18Provider>
      <FavIcon />
      <Theme>
        <ErrorBoundary>
          <SetupRumTestError />
          <AppHeader />
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          />
          <DataLoader>
            <NiceModal.Provider>{children}</NiceModal.Provider>
          </DataLoader>
        </ErrorBoundary>
      </Theme>
    </I18Provider>
  )
}

export default App

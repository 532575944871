import { createStore, combineReducers, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import createWebStorage from "redux-persist/es/storage/createWebStorage"
import thunk from "redux-thunk"
import dayjs from "dayjs"

import auth from "./reducer_auth"
import { AUTH } from "../actions/auth"

const createReducer =
  (key, initialState) =>
  (state = initialState, action) => {
    if (action.type === key) {
      return action.payload !== undefined ? action.payload : state
    }
    return state
  }

const reducersDefaultValues = {
  treatedMissingFoods: null,
  missingFoods: null,
  filteredFoods: null,
  filteredMedications: null,
  medications: null,
  call: null,
  selectedCallTab: null,
  inAction: false,
  supportTableRefresh: 0,
  labVisitResearchStage: null,
  participant: null,
  adminReportsData: null,
  signupCohort: null,
  didAnswerAntibioticsQuestion: null,
  minimumVisitDate: null,
  appointmentsSchedluingAvailableShifts: null,
  appointmentsScheduledShift: null,
  appointmentsSchedluingSelectedDate: null,
  appointmentsSchedluingSelectedShift: null,
  shiftCalendarCurrentMonth: dayjs().month(),
  currentLanguageCode: null,
  configuration: null,
  allCohorts: null,
  authPayload: null,
}

const reducers = {}
Object.entries(reducersDefaultValues).forEach(([key, defaultValue]) => {
  reducers[key] = createReducer(key, defaultValue)
})

const appReducer = combineReducers({
  auth,
  ...reducers,
})

const rootReducer = (state, action) => {
  if (action.type === AUTH && !action.payload) {
    state = {
      medications: state.medications,
      currentLanguageCode: state.currentLanguageCode
    }
  }
  return appReducer(state, action)
}

export function createPersistStore() {
  if (typeof window === "undefined") {
    return {
      getItem() {
        return Promise.resolve(null)
      },
      setItem() {
        return Promise.resolve()
      },
      removeItem() {
        return Promise.resolve()
      },
    }
  }
  return createWebStorage("local")
}

const storage = typeof window !== "undefined" ? createWebStorage("local") : createPersistStore()

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "config", "currentLanguageCode", "authPayload"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store)

if (typeof localStorage !== "undefined" && localStorage.length === 0) {
  persistor.purge()
}

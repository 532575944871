import { useState, useEffect, useCallback } from "react"
import axios from "axios"
import { enqueueSnackbar } from "notistack"
import { API_ROOT_URL } from "../requests"
import useTranslation from "./use_translation"
import { useSelector } from "react-redux"
import { captureException } from "@sentry/nextjs"

const cachedRequests = {}

const makeRequest = (t, path) => {
  const request = axios.get(`${API_ROOT_URL}${path}`)
  request.catch((e) => {
    if (e.response?.status === 401) {
      console.log(`Unauthorized Request: ${path}`)
    } else if (!e.response) {
      console.error(`Network Error: ${path} ${e} ${e.response} ${e.message}`)
      console.error(e)
    } else {
      console.error(`Request Error: ${path} ${e} ${e.response} ${e.message}`)
      console.error(e)
      captureException(e)
      enqueueSnackbar(t("unexpectedError"), { variant: "error", autoHideDuration: 5000 })
    }
  })
  return request
}

const getOrCreateRequest = (t, path, useCache) => {
  const request = (useCache && cachedRequests[path]) || makeRequest(t, path)
  cachedRequests[path] = request
  return request
}

export const useFetchCallback = (path, { useCache = false } = {}) => {
  const { t } = useTranslation()
  const [isFetching, setIsFetching] = useState(false)

  const fetch = useCallback(
    async (notifyOnError) => {
      setIsFetching(true)
      try {
        const notifyFunction = notifyOnError ? enqueueSnackbar : () => {}
        const { data } = await getOrCreateRequest(t, path, notifyFunction, useCache)
        return [data, null]
      } catch (error) {
        return [null, error]
      } finally {
        setIsFetching(false)
      }
    },
    [enqueueSnackbar, path, useCache],
  )

  return [fetch, isFetching]
}

export const useFetch = (path, options) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [fetch, isFetching] = useFetchCallback(path, options)
  const waitForAuth = useSelector((s) => !s.auth && path.startsWith("/protected"))

  const refresh = useCallback(
    ({ notifyOnError = false } = {}) => {
      ;(async () => {
        delete cachedRequests[path]
        const [data, error] = await fetch(notifyOnError)
        setError(error)
        if (!error) {
          setData(data)
        }
      })()
    },
    [fetch, path],
  )

  useEffect(() => {
    if (waitForAuth) {
      return
    }
    refresh({ notifyOnEror: true })
  }, [refresh, waitForAuth])

  return [data, isFetching, error, refresh]
}
